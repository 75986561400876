import { render, staticRenderFns } from "./AddBrick.vue?vue&type=template&id=7dcf73d7&scoped=true&"
import script from "./AddBrick.vue?vue&type=script&lang=ts&"
export * from "./AddBrick.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dcf73d7",
  null
  
)

export default component.exports