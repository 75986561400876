
import { getBrickIcon } from '@/scripts/bricks/getIcon';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SelectedBrickDetails extends Vue {
  // Props

  @Prop() selectedBrick: any;

  // Methods

  /**
   * Get the image of a brick
   */
  getBrickIcon = (brickFamily: string, brickimage: string) => getBrickIcon(brickFamily, brickimage);
}
