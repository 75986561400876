
import { Component, Vue, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import SignupLoginLayout from '@/components/shared/layouts/SignupLoginLayout.vue';
import { EMAIL_REGEX, USERNAME_REGEX, PASSWORD_REGEX } from '@/scripts/shared';
import { required, sameAs } from 'vuelidate/lib/validators';
import { Getter, Action, Mutation } from 'vuex-class';
import { Validations } from 'vuelidate-property-decorators';
import * as types from '@/store/types';

const namespace: string = 'user';
@Component({
  components: {
    SignupLoginLayout,
  },
})
export default class ForgetPassword extends Vue {
  @Prop() param!: string;

  private email:string = '';

  @Action(types.REQUEST_RESET_PASSWORD, { namespace })
  requestResetPassword: any;

  @Getter(types.GET_FORGET_PASSWORD_REQUEST_RESULT, { namespace })
  isRequestForgetPassSent: any;

  created() {
    this.email = this.param;
  }

  get emailErrors() {
    const errors: TranslateResult[] = [];
    if (!this.$v.email.$dirty) return errors;

    if (!this.$v.email.required) errors.push(this.$t('required_field'));
    if (!this.$v.email.matches) errors.push(this.$t('email_invalid'));
    return errors;
  }

  get isSendDisabled(): boolean {
    return this.$v.$invalid;
  }

  @Validations()
  validations = {
    email: {
      required,
      matches(value: string) {
        return EMAIL_REGEX.test(value);
      },
    },
  };

  handleSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.requestResetPassword({
      email: this.email,
    });
  }
}
