
import { Action, Getter } from 'vuex-class';
import {
  Component, Vue, Watch, Prop,
} from 'vue-property-decorator';
import * as types from '@/store/types';

const namespace: string = 'brick';
const namespaceUser: string = 'user';

@Component
export default class AddBrick extends Vue {
  @Prop() isAllowedToUpload!: boolean;

  @Action(types.ADD_BRICK, { namespace }) addBrick: any;

  @Action(types.ADD_BRICK_CLEAR_STATE, { namespace }) clearState: any;

  @Getter(types.GET_ADD_BRICK_ERROR, { namespace }) errorMsg: any;

  @Getter(types.GET_ADD_BRICK_LOADING, { namespace }) loading: any;

  @Getter(types.GET_ADD_BRICK_SUCCESS, { namespace }) success: any;

  @Getter(types.GET_USER, { namespace: namespaceUser }) getUser: any;

  public file: any = [];

  @Watch('success')
  private onGetAddBrickSuccessChanged(value: boolean) {
    if (value) {
      this.reset();
    }
  }

  private reset() {
    this.clearState();
    this.file = null;
  }

  private upload() {
    const form = new FormData();
    if (this.file.name.length > 0) {
      form.append('package', this.file);
    }
    this.addBrick({ userId: this.getUser.id, file: form });
  }
}
