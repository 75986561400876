
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import SelectedBrickDetails from '@/components/packages/packageDetails/SelectedBrickDetails.vue';
import { FETCH_PACKAGE_BRICKS, GET_BRICKS_OF_PACKAGE } from '@/store/types';
import { Package } from '@/store/packages/models';
import { getBrickIcon } from '@/scripts/bricks/getIcon';

const namespace = 'pkg';

@Component({
  components: {
    SelectedBrickDetails,
  },
})
export default class BricksInPackage extends Vue {
  // Props

  @Prop() pkg!: Package;

  // Getters

  @Getter(GET_BRICKS_OF_PACKAGE, { namespace }) bricks: any;

  // Actions

  @Action(FETCH_PACKAGE_BRICKS, { namespace }) fetchPackageBricks: any;

  // Data

  private dialog = false;

  private selectedBrick = null;

  private loading = false;

  // Vue Life Cycle Hooks

  async mounted() {
    await this.fetchBricks();
  }

  // Methods

  /**
   * Closes the brick dialog showing
   * the details of clicked brick
   */
  clearBrick() {
    this.selectedBrick = null;
    this.dialog = false;
  }

  /**
   * Fetches all bricks in a certain
   * package and toggles the UI loading
   */
  async fetchBricks() {
    this.loading = true;

    await this.fetchPackageBricks({ id: this.pkg.id });

    this.loading = false;
  }

  /**
   * Get the image of a brick
   */
  getBrickIcon = (brickFamily: string, brickimage: string) => getBrickIcon(brickFamily, brickimage);
}
