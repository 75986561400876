
import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { GET_ALL_PACKAGES, GET_PACKAGES } from '@/store/types';
import { getPackageIcon } from '@/scripts/bricks/getIcon';
import { Package } from '@/store/packages/models';
import PackageDetails from '@/components/packages/packageDetails/PackageDetails.vue';
import VersionDropdown from '@/components/packages/packageDetails/VersionDropdown.vue';
import { namespaces } from '@/scripts/namespaces';
import { now } from 'jquery';

@Component({
  components: {
    PackageDetails,
    VersionDropdown,
  },
})
export default class PackageList extends Vue {
  // Getters
  @Getter(GET_ALL_PACKAGES, { namespace: namespaces.PACKAGE }) packages: any;

  @Getter(GET_PACKAGES, { namespace: namespaces.BRICK })
  getPackages: any;

  // Data
  private dialog = false;

  private pkg: Package | null = null;

  private packageSearchTerm: string = '';

  private renderComponent: boolean = true;

  private installedPackageIds: string[] = [];

  private listOfPackages: any = [];

  private packageMap = new Map();

  mounted() {
    this.installedPackageIds = this.getPackages.map((pkg: Package) => pkg.id);

    this.packages.forEach((element : any) => {
      if (this.packageMap.get(element.name) === undefined) {
        this.packageMap.set(element.name, [element]);
      } else {
        this.packageMap.get(element.name).push(element);
      }
    });

    this.packageMap.forEach((value: any, key: string) => {
      if (value) {
        value.sort((firstEl: any, secondEl: any) => {
          if (firstEl.version < secondEl.version) { return 1; }
          if (firstEl.version > secondEl.version) { return -1; }
          return 0;
        });
      }
      this.listOfPackages.push({ value, selectedIndex: 0 });
    });
  }

  // Computed
  get filteredPackages() {
    // return all packages if search term is empty
    if (!this.packageSearchTerm) {
      return this.listOfPackages;
    }

    // return filtered packages
    return this.listOfPackages.filter(
      (pkg: any) => pkg.value[pkg.selectedIndex].name.toLowerCase().includes(
        this.packageSearchTerm.toLowerCase(),
      ),
    );
  }

  // Methods

  /**
   * Returns the default icon or package
   * icon with proper BASE64 prefix
   *
   * @param pkg
   */
  getPackageIcon = (pkg: Package) => getPackageIcon(pkg.image);

  /**
   * Opens the details of a package
   * in a dialog box
   *
   * @param pkg
   */
  openDetails(pkg: Package) {
    this.pkg = pkg;
    this.dialog = true;
  }

  /**
   * Closes the dialog box of a package
   * and sets the package to null
   */
  closeDetails() {
    this.dialog = false;
    this.pkg = null;
  }

  isPackageInstalled(packageId: string) {
    return this.installedPackageIds.includes(packageId);
  }

  forceRender(pkgId: string) {
    this.installedPackageIds.push(pkgId);
    this.renderComponent = false;

    this.$nextTick(() => {
      this.renderComponent = true;
    });
  }

  ChangeSelectedIndex(packageIndex: any, selectedVersionIndex: any) {
    this.listOfPackages[packageIndex].selectedIndex = selectedVersionIndex;
  }
}
