
import { Action, Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import * as types from '@/store/types';
import Toolbar from '@/components/bricks/ToolbarBrick.vue';
import Loading from '@/components/shared/Loading.vue';
import BrickDialog from '@/components/bricks/BrickDialog.vue';
import PackageDialog from '@/components/bricks/PackageDialog.vue';
import AddBrick from '@/components/bricks/AddBrick.vue';
import { InstalledBrick, Package } from '@/store/bricks/models';
import DeletePackage from '@/components/bricks/DeletePackage.vue';
import { getBrickIcon } from '@/scripts/bricks/getIcon';
import { emptyWorkspace, Workspace } from '@/store/workspace/models';
import FailedMessage from '@/components/shared/FailedMessage.vue';
import { getVersionFromUrn } from '@/scripts/brickVersion';

const namespace: string = 'brick';
const namespaceUser: string = 'user';
const namespaceWorkspace: string = 'workspace';

@Component({
  components: {
    AddBrick,
    Loading,
    Toolbar,
    BrickDialog,
    DeletePackage,
    PackageDialog,
    FailedMessage,
  },
})
export default class BrickList extends Vue {
  @Action(types.FETCH_BRICKS, { namespace })
  fetchBricks: any;

  @Action(types.FETCH_PACKAGES, { namespace })
  fetchPackages: any;

  @Action(types.DELETE_PACKAGE, { namespace })
  deletePackage: any;

  @Action(types.FETCH_LOGGED_IN_USER, { namespace: namespaceUser })
  fetchLoggedInUser: any;

  @Getter(types.GET_BRICK_NAMES, { namespace })
  getBrickNames: any;

  @Getter(types.GET_LOADING, { namespace })
  getLoading: any;

  @Getter(types.GET_PACKAGES, { namespace })
  getPackages: any;

  @Getter(types.GET_USER, { namespace: namespaceUser })
  getUser: any;

  @Getter(types.GET_USER_WORKSPACES, { namespace: namespaceWorkspace })
  getWorkspaces: any;

  public deleteDialog = false;

  public itemIdToDelete = '';

  public itemNameToDelete = '';

  public packageDialog = false;

  public selectedPackage: Package = { id: '' };

  public selectedBrick: InstalledBrick = {
    id: '',
    name: '',
    family: '',
    parameters: [],
    description: '',
    package: '',
    autoscale_queue_level: 0,
    autoscale_max_instances: 0,
    exit_after_idle_seconds: 0,
    inputPorts: [],
    outputPorts: [],
    brickimage: '',
    flowId: '',
    owner: '',
    permissions: {
      public: false,
      readOnly: false,
    },
  };

  private failedToFetch: string = '';

  @Watch('workspace')
  async onWorkspaceChange() {
    await this.init();
  }

  async init() {
    if (this.workspace) {
      await this.fetchPackages(this.workspace.owner.id).catch((e: any) => {
        this.failedToFetch = e.message;
      });
      await this.fetchBricks(this.workspace.owner.id);
    }
  }

  async beforeMount() {
    await this.fetchLoggedInUser();
    await this.init();
  }

  private getBricksByPackage(brickPackage: any) {
    const bricks = this.getBrickNames;
    return bricks.filter((brick: InstalledBrick) => brick.packageId === brickPackage.id);
  }

  private setSelectedBrick(brick: InstalledBrick) {
    this.selectedBrick = brick;
  }

  // Delete a package
  private deleteItem(item: any) {
    this.deleteDialog = true;
    this.itemIdToDelete = item.id;
    this.itemNameToDelete = item.name;
  }

  // eslint-disable-next-line class-methods-use-this
  private getBrickIcon(brickFamily: string, brickimage: string = '') {
    return getBrickIcon(brickFamily, brickimage);
  }

  /**
   * Opens the package dialog
   * that shows the package info
   */
  private openPackageDialog(selectedPackage: Package) {
    this.packageDialog = true;
    this.selectedPackage = selectedPackage;
  }

  private clearSelectedBrick() {
    this.selectedBrick = {
      id: '',
      name: '',
      family: '',
      parameters: [],
      description: '',
      package: '',
      autoscale_queue_level: 0,
      autoscale_max_instances: 0,
      exit_after_idle_seconds: 0,
      inputPorts: [],
      outputPorts: [],
      brickimage: '',
      flowId: '',
      owner: '',
      permissions: {
        public: false,
        readOnly: false,
      },
    };
  }

  get workspace(): Workspace {
    const { workspaceId } = this.$route.params;
    if (this.getWorkspaces) {
      return this.getWorkspaces.find((w: Workspace) => w.id === workspaceId);
    }
    return emptyWorkspace;
  }

  get isUserOwner(): boolean {
    const { workspaceId } = this.$route.params;
    if (workspaceId && this.getUser) {
      return !!this.getWorkspaces.find(
        (w: Workspace) => w.id === workspaceId && w.owner.id === this.getUser.id,
      );
    }
    return false;
  }

  getVersion = (urn: string) => getVersionFromUrn(urn);
}
